import { Bodies } from 'matter-js';

import createMatterConstaintAnchors from '../../utils/matter/createMatterConstaintAnchors';

const DAMPING = 0.15;
const STIFFNESS = 0.01;
const SHOW_CONSTRAINTS = false;

export default function ({ x, y, width, height, scale, texture }) {
  const scaledSize = {
    width: width * scale,
    height: height * scale,
    halfHeight: height * 0.5 * scale,
    halfWidth: width * 0.5 * scale,
  };

  const body = Bodies.rectangle(x, y, scaledSize.width, scaledSize.height, {
    render: {
      sprite: {
        texture,
        xScale: scale,
        yScale: scale,
      },
    },
  });

  const anchors = createMatterConstaintAnchors({
    positionVector: {
      x,
      y: y + scaledSize.halfHeight + 50,
    },
    matterBody: body,
    size: scaledSize,
    stiffness: STIFFNESS,
    damping: DAMPING,
    showConstraints: SHOW_CONSTRAINTS,
  });

  return [body, ...anchors];
}
