/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Engine, Render, World, Runner, Mouse, MouseConstraint } from 'matter-js';

import resetScene from '../../utils/matter/resetScene';
import window from '../../utils/window';

import useWindowSize from '../../hooks/useWindowSize';
import useInView from '../../hooks/useInView';

import * as styles from './HeroFloatingImages.module.scss';

import firstChatImage from '../../images/icons/chat-1.png';
import secondChatImage from '../../images/icons/chat-2.png';

import createFloatingImage from './createFloatingImage';
import fixMouseScrolling from '../../utils/matter/fixMouseScrolling';
import scaleValueCurry from '../../utils/matter/scaleValueCurry';

const MOBILE_THRESHOLD = 900;
const PIXEL_RATIO = Math.min(1.75, window.devicePixelRatio);
const MOBILE_SCALE = 0.4;

/**
 * This is the HeroFloatingImages component.
 *
 * @author Maximilian Øystå Lloyd <max@apt.no>
 *
 * @return {JSX}
 */

function heightViewportUnits() {
  return window.innerHeight / 100;
}

const HeroFloatingImages = ({ content }) => {
  const size = useWindowSize();
  const containerRef = useRef();
  const prevWidth = useRef();
  const isRunning = useRef(true);
  const [scene, setScene] = useState();
  const [initialRenderDone, setInitialRenderDone] = useState(false);
  const inView = useInView(containerRef);

  const { title, firstImage, secondImage, thirdImage } = content;

  const firstImageUrl = firstImage?.asset?.url;
  const secondImageUrl = secondImage?.asset?.url;
  const thirdImageUrl = thirdImage?.asset?.url;

  useEffect(() => {
    if (window.innerWidth > MOBILE_THRESHOLD) {
      initScene(1);
    } else {
      initScene(MOBILE_SCALE);
    }

    setInitialRenderDone(true);
  }, []);

  function initScene(scale = 0.5) {
    const scaleValue = scaleValueCurry(scale);
    const height = heightViewportUnits() * 80; // providedHeight || 700;
    const width = window.innerWidth;
    const centerWidth = width / 2;
    const centerHeight = height / 2;

    const engine = Engine.create();
    const render = Render.create({
      element: containerRef.current,
      engine,
      options: {
        wireframes: false,
        background: '#FBFBFB',
        width,
        height,
      },
    });
    const runner = Runner.create();

    const { world } = engine;

    const firstImageBodies = createFloatingImage({
      x: centerWidth - scaleValue(400),
      y: centerHeight + 80,
      width: 366,
      height: 380,
      scale,
      texture: firstImageUrl,
    });

    const secondImageBodies = createFloatingImage({
      x: centerWidth + scaleValue(180),
      y: centerHeight + scaleValue(200),
      width: 205,
      height: 218,
      scale,
      texture: secondImageUrl,
    });

    const thirdImageBodies = createFloatingImage({
      x: centerWidth + scaleValue(450),
      y: centerHeight - scaleValue(100),
      width: 204,
      height: 222,
      scale,
      texture: thirdImageUrl,
    });

    const firstChatBoxBodies = createFloatingImage({
      x: centerWidth - scaleValue(175),
      y: centerHeight + scaleValue(275),
      width: 90,
      height: 98,
      scale,
      texture: firstChatImage,
    });

    const secondChatBoxBodies = createFloatingImage({
      x: centerWidth,
      y: centerHeight - scaleValue(175),
      width: 104,
      height: 90,
      scale,
      texture: secondChatImage,
    });

    const mouse = Mouse.create(render.canvas);
    mouse.pixelRatio = PIXEL_RATIO;

    const mouseConstraint = MouseConstraint.create(engine, {
      element: render.canvas,
      mouse,
      constraint: {
        render: {
          visible: false,
        },
        stiffness: 0.008,
      },
    });

    fixMouseScrolling(mouseConstraint, () => {
      window.scrollTo(0, 580);
    });

    World.add(world, [
      ...firstImageBodies,
      ...secondImageBodies,
      ...thirdImageBodies,
      ...firstChatBoxBodies,
      ...secondChatBoxBodies,
    ]);

    engine.world.gravity.y = -2;

    World.add(world, [mouseConstraint]);

    Render.setPixelRatio(render, PIXEL_RATIO);

    setScene({ render, engine, runner });
  }

  useEffect(() => {
    if (!inView) return;
    if (prevWidth.current === size.width) return;

    prevWidth.current = size.width;

    if (!scene || !initialRenderDone) return;

    clearTimeout(window.resizeTimer);

    window.resizeTimer = setTimeout(() => {
      if (!scene.render.canvas) return;

      isRunning.current = false;
      resetScene(scene);

      if (window.innerWidth <= MOBILE_THRESHOLD) {
        initScene(MOBILE_SCALE);
      } else {
        initScene(1);
      }
    }, 500);
  }, [size.width, inView]);

  useEffect(() => {
    if (!scene || inView === null) return;
    // if (prevWidth.current === size.width) return;

    if (!inView) {
      Render.stop(scene.render);
      Runner.stop(scene.runner);
      isRunning.current = false;
    } else if (!isRunning.current) {
      isRunning.current = true;
      Render.run(scene.render);
      Runner.start(scene.runner, scene.engine);
    }
  }, [inView, scene]);

  return (
    <div
      className={classNames(styles.main, {
        [styles.visible]: inView,
      })}
      ref={containerRef}
    >
      <h1 className={styles.header}>{title}</h1>
      <div className={styles.scene} id="scene" />
    </div>
  );
};

HeroFloatingImages.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    firstImage: PropTypes.shape.isRequired,
    secondImage: PropTypes.shape.isRequired,
    thirdImage: PropTypes.shape.isRequired,
  }),
};

export default HeroFloatingImages;
