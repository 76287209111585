import { Mouse, Events } from 'matter-js';

export default function (mouseConstraint) {
  const { element } = mouseConstraint.mouse;

  let touchStart;
  mouseConstraint.mouse.element.addEventListener('touchstart', (event) => {
    if (!mouseConstraint.body) {
      touchStart = event;
    }
  });

  mouseConstraint.mouse.element.addEventListener('touchend', (event) => {
    if (!mouseConstraint.body) {
      const startY = touchStart.changedTouches[0].clientY;
      const endY = event.changedTouches[0].clientY;
      const delta = Math.abs(startY - endY);

      if (delta > 0) {
        window.scrollTo(0, 580);
      }
    }
  });

  element.removeEventListener('mousewheel', mouseConstraint.mouse.mousewheel);
  element.removeEventListener('DOMMouseScroll', mouseConstraint.mouse.mousewheel);
}
