import React from 'react';
import { graphql } from 'gatsby';

/*
	Components
*/
import App from '../components/App/App';
import Components from '../components/Components';
import Nav from '../components/Nav/Nav';
import Footer from '../components/Footer/Footer';
import HeroFloatingImages from '../components/HeroFloatingImages/HeroFloatingImages';

const HowWeTalk = ({ data }) => {
  const { floatingImagesHero, contentBlocks, footer } = data.sanityHowWeTalkPage;
  console.log(data);
  return (
    <App>
      <Nav navItems={[]} />
      <HeroFloatingImages content={floatingImagesHero} />
      {contentBlocks.map((block) => Components(block))}
      <Footer data={footer} />
    </App>
  );
};

export const query = graphql`
  {
    sanityHowWeTalkPage(_id: { eq: "howWeTalk1" }) {
      id
      floatingImagesHero {
        title
        firstImage {
          asset {
            url
            assetId
          }
        }
        secondImage {
          asset {
            url
            assetId
          }
        }
        thirdImage {
          asset {
            url
            assetId
          }
        }
      }
      contentBlocks {
        ... on SanitySlides {
          _key
          _type
          title
          slides {
            # _rawExampleTextCorrect
            # _rawExampleTextWrong
            text
            _type
            image {
              asset {
                url
              }
            }
          }
        }
        ... on SanityTextSection {
          _key
          _type
          _rawText
          bgcolor
          heading
        }
        ... on SanityInfoBoxSection {
          _key
          _type
          infoBoxes {
            icon {
              asset {
                path
                url
                title
              }
            }
            _rawText
          }
          title
        }
      }
      footer {
        text
        link {
          path
          title
        }
      }
    }
  }
`;

export default HowWeTalk;
