import { Constraint } from 'matter-js';
// creates to constraints for a Body to prevent it from rotating
// pass it in as an object to make API more obvious for the caller
export default function ({
  positionVector,
  matterBody,
  size,
  showConstraints = false,
  stiffness,
  damping,
}) {
  const leftConstraint = Constraint.create({
    pointA: positionVector,
    bodyB: matterBody,
    pointB: { x: -size.halfWidth, y: size.halfHeight },
    stiffness,
    damping,
    render: {
      visible: showConstraints,
    },
  });

  const rightConstraint = Constraint.create({
    pointA: positionVector,
    bodyB: matterBody,
    pointB: { x: size.halfWidth, y: size.halfHeight },
    stiffness,
    damping,
    render: {
      visible: showConstraints,
    },
  });

  return [leftConstraint, rightConstraint];
}
